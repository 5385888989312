.blog-entry {
	-webkit-transition: 0.3s all ease;
	-o-transition: 0.3s all ease;
	transition: 0.3s all ease;
	position: relative;
	top: 0;
	-webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
	background: #333;
	&:hover {
		-webkit-box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
		box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
		top: -2px;
	}
	&:active {
		-webkit-box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
		box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
		top: -2px;
	}
	&:focus {
		-webkit-box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
		box-shadow: 0 5px 50px -10px rgba(0, 0, 0, 0.1);
		top: -2px;
	}
	.blog-entry-text {
		border-top: none;
		padding: 30px;
		h3 {
			font-size: 20px;
			margin-bottom: 10px;
			line-height: 1.5;
			a {
				color: #fff;
				&:hover {
					color: #bac964;
				}
			}
		}
	}
	.meta {
		font-size: 14px;
		a {
			display: inline-block;
			margin-right: 10px;
			color: rgba(255, 255, 255, 0.4);
			&:hover {
				color: #bac964;
			}
		}
	}
}
