.resume-container {
  span {
    font-size: 0.8em;
  }
}

.resume-item {
  padding: 30px;
  background: $primary;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
  border: 4px solid transparent;
  border-radius: 7px;

  @include max-break-point(tablet) {
    padding: 20px;
  }

  @include max-break-point(mobile-phone) {
    padding: 10px;
  }

  .date {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    color: $light-grey;
  }
  .description {
    margin: 1em 0;
    color: $light-grey;
  }
  .school {
    display: block;
    font-size: 16px;
    color: $dimgrey;
  }
  h3 {
    color: $primary-color;
    font-size: 20px;
  }

  .readmore {
    color: $primary-color;
  }

  * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.timeline-box {
  max-width: 550px;
  margin: 0 25px;
  position: relative;

  .date {
    @extend .text-center,
      .position-relative,
      .d-inline-block,
      .w-auto,
      .fw-bolder,
      .px-1,
      .fs-2;
    line-height: 1;
    z-index: 1;
    background: $dark-bg;
    transform: translate(0, -50%);

    @include max-break-point(tablet) {
      font-size: inherit;
    }
  }
  p {
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    font-weight: 400;
    margin-bottom: 26px;
    font-family: "Inter", sans-serif;
    &:last-of-type {
      margin-bottom: 15px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 27.5px;
    height: 1px;
    width: 100%;
  }
}

.start-point {
  @extend .position-relative, .d-flex, .flex-column, .align-items-center;

  h4 {
    font-size: 17px;
    line-height: 23px;
    color: $black;
    font-weight: 600;
    text-transform: uppercase;
    background-color: $primary-color;
    border-radius: 16px;
    display: block;
    width: auto;
    text-align: center;
    padding: 5px 25px;
    position: relative;
    margin-bottom: 45px;
    font-family: "Inter", sans-serif;
  }

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0px;
    background-color: #aaa;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    border-left: 1px solid #aaa;
  }

  .corner.bl {
    left: 50%;
    bottom: 0px;
    transform: translateY(100%);
    top: unset;
  }
}

.black-dot {
  width: 21px;
  height: 21px;
  background-color: $primary-color;
  border-radius: 50%;
  display: block;
  margin: 0 auto 62px;
  z-index: 1;

  @include max-break-point(tablet) {
    margin: 0;
  }
}

.timeline-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 0px;
  position: relative;

  .horizontal-line {
    border-top: 1px solid #aaa;
    width: calc(100% - 150px);
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;
    transform: translate(-50%, 0%);

    &.half {
      width: calc(51% - 150px);
    }
    &.right {
      left: auto;
      right: -13%;
    }
  }
  .verticle-line {
    margin: 0;
    padding: 0;
    width: 2px;
    border-right: 1px solid #aaa;
    height: calc(100% - 150px);
    position: absolute;
    top: 0;
    right: 0;
    margin: auto 0;
    bottom: 0;
  }
  &:nth-child(2n + 2) {
    justify-content: space-around;
    padding: 0 2% !important;
    margin: 0 !important;
    flex-direction: row-reverse;
    .verticle-line {
      left: 0;
      right: inherit;
    }
    .corner {
      right: inherit;
      left: 0;
      border-left: 1px solid #aaa;
      border-right: 0;
      border-radius: 70px 0 0 0;
      right: inherit;
      left: 0;
      border-left: 1px solid #aaa;
      border-right: 0;
      border-radius: 70px 0 0 0;
    }
    .corner.bottom {
      border-radius: 0 0 0 70px;
    }
    .start-point.end-point {
      left: 0;
      transform: translateX(-50%);
      right: unset;

      @include max-break-point(tablet) {
        right: 50%;
        transform: translateX(50%);
      }
    }
  }
  &:first-child {
    padding: 0 2%;
    margin: 0 0px 0 auto;
    max-width: 50%;
    justify-content: space-between;

    &::after {
      left: -54px;
      width: 97%;
      transform: none;
    }

    .timeline-box {
      &:first-child {
        width: 100%;
      }
      &::after {
        left: auto;
        right: 0;
        -webkit-transform: translate(100%, -50%) rotate(0deg);
        transform: translate(100%, -50%) rotate(0deg);
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  &:last-child {
    .corner.bottom {
      display: none;
    }
    .verticle-line {
      &::after {
        display: none;
      }
    }
    .start-point.end-point {
      left: 1px;
      display: block;
    }
  }
  &:nth-child(2n + 1) {
    .timeline-box {
      &:last-child {
        .timeline-box-wrap {
          text-align: center;
        }
      }
    }
  }
  .timeline-box {
    &:first-child {
      .timeline-box-wrap {
        .date {
          right: 0em;
          width: 15em;
        }
      }
    }
    &:nth-child(2n + 2) {
      .timeline-box-wrap {
        .date {
          left: 0em;
          width: 15em;
        }
      }
    }
    &:nth-child(3) {
      &::after {
        display: none;
      }
    }
  }
  &.first {
    max-width: 100%;
    justify-content: center;
    flex-direction: row;
    padding: 0;

    .start-point {
      padding: 5em 0%;

      @include max-break-point(mobile-phone) {
        padding: 0;
      }

      &:before {
        top: 23%;
        height: 54%;
        left: 77.5px;
      }

      .black-dot {
        margin: 0px 66px 62px;
      }
      .horizontal-line {
        width: calc(100% - 195px);
        left: 54%;
      }

      .corner.top,
      .corner.bottom {
        left: 77px;
      }
    }
  }
}
.education-row {
  &:nth-child(2n + 1) {
    padding: 0 2%;
    margin: 0 0px 0 auto;
    max-width: 50%;
    justify-content: space-between;
    .timeline-box {
      &:first-child {
        @extend .w-100;
      }
      &:nth-child(2) {
        @extend .d-none;
      }
    }
  }
}

.corner {
  position: absolute;
  border-right: 1px solid #aaa;
  width: 76px;
  height: 76px;
  right: 0;
  border-top: 1px solid #aaa;
  top: 74.9px;
  border-radius: 0 70px 0 0;
  top: 0;

  &.bottom {
    bottom: -76px;
    top: inherit;
    border-radius: 0;
    border-top: 0;
    border-bottom: 1px solid #aaa;
    border-radius: 0 0 70px 0;
    right: 0;
    z-index: 1;
    bottom: -1px;
  }
}

.timeline-main {
  margin-top: 75px;
}

.corner.bl {
  bottom: 0;
  left: 0;
  border-left: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  border-radius: 0 0 0px 100%;
  border-right: none;
  border-top: none;
  height: 76px;
  width: 76px;
}

.timeline-box-wrap {
  padding: 45px 0px 0;
  position: relative;
  padding: 0;
  margin-bottom: 90px;
  .timeline-content {
    img {
      filter: drop-shadow(0 0 27px rgba(0, 0, 0, 0.16));
      -webkit-filter: drop-shadow(0 0 27px rgba(0, 0, 0, 0.16));
      z-index: 2;
      position: relative;
    }
  }
}

.timeline-content-txt {
  padding: 10px 0 0 0;

  @include max-break-point(mobile-phone) {
    display: none;
  }
}

.timeline-box {
  &.img-right {
    .timeline-content {
      display: flex;
      align-items: flex-start;
      img {
        margin-left: 18px;
      }
    }
    .timeline-content-txt {
      text-align: right;
    }
    text-align: center;
  }

  &.img-left {
    .timeline-content {
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      img {
        margin-right: 18px;
      }
    }
    .timeline-content-txt {
      text-align: left;
    }
    text-align: center;
  }
}

.start-point.end-point {
  @extend .position-absolute, .bottom-0, .start-0;
  transform: translateX(50%);

  &:before {
    display: none;
  }
}

.timeline-content-img {
  .timeline-content {
    position: relative;
  }
}

/*==============Responsive==============*/

@media screen and (max-width: 1800px) {
  .timeline-box {
    max-width: 460px;
  }
  .timeline-box.img-right .timeline-content img,
  .timeline-box.img-left .timeline-content img {
    max-width: 220px;
    max-height: 230px;
  }
  .timeline-box-wrap .timeline-content img {
    max-width: 100%;
  }
}

@media screen and (max-width: 1600px) {
  .timeline-box {
    max-width: 380px;
  }
  .timeline-box.img-right .timeline-content img,
  .timeline-box.img-left .timeline-content img {
    max-width: 180px;
    max-height: 190px;
  }
  .timeline-box h6 {
    font-size: 50px;
  }
  .about-timeline {
    padding: 120px 0 100px;
  }
  .timeline-box p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1440px) {
  .about-timeline {
    padding: 100px 0 80px;
  }
  .start-point h4 {
    font-size: 15px;
    letter-spacing: 0;
  }
  .timeline-box h6 {
    font-size: 40px;
  }
  .timeline-box p {
    font-size: 15px;
    line-height: 1.4;
  }
  .timeline-box {
    max-width: 300px;
  }
  .timeline-box.img-right .timeline-content img,
  .timeline-box.img-left .timeline-content img {
    max-width: 140px;
    max-height: 200px;
  }
  .timeline-box-wrap .timeline-content img {
    max-width: 100%;
  }
  .timeline-row:first-child .timeline-box:nth-child(2) {
    width: 150px;
  }
}

@media screen and (max-width: 1199px) {
  .about-timeline {
    padding: 90px 0 60px;
  }
  .timeline-box h6 {
    font-size: 34px;
  }
  .timeline-box {
    max-width: 240px;
  }
  .timeline-row:first-child {
    padding: 0 0 0 60px;
  }
  .timeline-row:first-child .timeline-box:first-child {
    width: 42%;
  }
  .timeline-box.img-right .timeline-content,
  .timeline-box.img-left .timeline-content {
    flex-wrap: wrap;
  }
  .timeline-box.img-right .timeline-content img,
  .timeline-box.img-left .timeline-content img {
    max-width: 180px;
    max-height: 200px;
  }
  .top-timeline-arrow.timeline-content-img .timeline-content::after {
    right: 0;
  }
  .img-right.timeline-content-img .timeline-content::after {
    top: -20px;
    right: -45px;
  }
  .timeline-box.img-right .timeline-content-txt {
    width: 90%;
  }
}

@media screen and (max-width: 1024px) {
  .corner {
    display: none;
  }
  .timeline-row .horizontal-line {
    display: none;
  }
  .timeline-row:first-child,
  .education-row:nth-child(2n + 1) {
    max-width: 100%;
    padding: 0;
  }
  .timeline-main {
    margin-top: 0;
  }
  .timeline-row .verticle-line {
    right: 50%;
    transform: translate(50%, 0);
    height: calc(100% - 0px);
  }
  .timeline-row:nth-child(2n + 2) .verticle-line {
    left: auto;
    right: 50%;
  }
  .verticle-line::after {
    display: none;
  }

  .timeline-row .timeline-box {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .timeline-row:first-child .timeline-box:first-child {
    width: 100%;
    margin: 0;
  }
  .timeline-row:first-child .timeline-box:nth-child(2) {
    width: 100%;
  }
  .timeline-box p br {
    display: none;
  }
  .timeline-box h6 {
    transform: translate(0, -0%);
    padding: 0;
  }

  .timeline-box.img-right .timeline-content-txt {
    width: 100%;
  }
  .start-point.end-point {
    right: 50%;
  }
  .start-point h4 {
    margin-bottom: 0;
  }
  .about-timeline {
    padding: 80px 0;
  }
  .timeline-box::after {
    -webkit-transform: translate(-100%, 100%) rotate(180deg);
    transform: translate(-100%, 100%) rotate(180deg);
    right: 50%;
    top: 20px;
    left: 50%;
  }
  .timeline-box-wrap {
    width: 50%;
    text-align: right;
    padding: 0 30px 0 0;
    margin: 40px 0 0 0;
  }
  .timeline-row .timeline-box img {
    margin: 0 0 0 auto;
  }
  .timeline-row:first-child .timeline-box:nth-child(2)::after {
    display: block;
  }
  .timeline-row:first-child .timeline-box:first-child img {
    margin: 0 0 0 auto;
  }
  .timeline-box.img-right .timeline-content img,
  .timeline-box.img-left .timeline-content img {
    margin: 0 0 0 auto;
  }
  .timeline-row .timeline-box:nth-child(2n) .timeline-box-wrap {
    margin: 40px 0 0 auto;
    padding: 0 0 0 30px;
    text-align: left;
  }
  .timeline-row:nth-child(2n + 1) .timeline-box::after {
    left: auto;
    right: 50%;
    -webkit-transform: translate(0%, 100%) rotate(180deg);
    transform: translate(0%, 100%) rotate(180deg);
    top: 20px;
  }
  .timeline-row .timeline-box:nth-child(2n)::after {
    transform: translate(100%, 100%) rotate(0deg);
    -webkit-transform: translate(100%, 100%) rotate(0deg);
    right: 50%;
    left: auto;
  }
  .timeline-row .timeline-box:nth-child(3)::after {
    display: block;
  }
  .timeline-box.img-left .timeline-content-txt {
    text-align: right;
  }
  .timeline-row:last-child {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 640px) {
  .about-timeline {
    padding: 50px 0;
  }
  .timeline-box h6 {
    font-size: 27px;
  }
  .timeline-box p {
    font-size: 14px;
    line-height: 1.4;
  }
  .timeline-box-wrap {
    margin: 30px 0 0 0;
  }
  .timeline-row .timeline-box:nth-child(2n) .timeline-box-wrap {
    margin: 30px 0 0 auto;
  }
  .timeline-box::after,
  .timeline-row:nth-child(2n + 1) .timeline-box::after {
    top: 6px;
  }
  .timeline-box-wrap .timeline-content img {
    max-width: 100%;
  }
  .timeline-box.img-right .timeline-content img,
  .timeline-box.img-left .timeline-content img {
    max-width: 100%;
    max-height: unset;
  }
}
