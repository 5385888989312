.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  padding: 3em;
  background-color: rgba($color: $grey, $alpha: 0.3);
  backdrop-filter: blur(10px);
  overflow: hidden;
  transform: translateX(-100%);
}

.sidebar.open{
    transform: translateX(0);
}