.form-control {
  border: none !important;
  background: $primary;
  color: #fff;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
  &:active {
    background: #404040;
  }
  &:focus {
    background: #404040;
  }
  &::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &:-moz-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
}
.site-form {
  .form-control {
    border: 1px solid #b3bdc6;
  }
}
.site-contact-details {
  padding: 0;
  margin: 0;
  li {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 20px;
    > span {
      color: $dimgrey;
      letter-spacing: 0.1em;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;
      display: block;
    }
  }
}
