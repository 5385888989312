@font-face {
  font-family: "Nexa Bold";
  font-style: bold;
  src: url("../../fonts/Nexa\ Bold.otf");
}

@font-face {
  font-family: "Didact Gothic";
  font-style: normal;
  src: url("../../fonts/DidactGothic-Regular.ttf");
}
@font-face {
  font-family: "BodoniFLF";
  font-style: normal;
  font-weight: bold;
  src: url("../../fonts/BodoniFLF\ Bold.ttf");
}

// Importing Inter font library from google fonts
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300;400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");