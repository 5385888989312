* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  // background-image: url(../../images/image.jpg);
  background-size: cover;
  background-color: $primary;
}

a {
  transition: 0.3s all;
  text-decoration: none;
  background-color: transparent;
}

.success-footer {
  flex-shrink: 0;
}

body.modal-open {
  overflow: hidden;
}
