.block-47 {
  .block-47-image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    display: block;
    img {
      border-radius: 50%;
      -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
  .block-47-quote {
    margin-left: 40px;
    padding: 30px;
    background: #333;
    border-radius: 7px;
    position: relative;
    -webkit-box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 30px -2px rgba(0, 0, 0, 0.1);
    &:before {
      content: "";
      border: 1px solid red;
      z-index: 2;
      position: absolute;
      left: -15px;
      border-width: 0 20px 20px 0;
      border-style: solid;
      border-color: transparent #333 transparent transparent;
    }
    .block-47-quote-author {
      font-size: 14px;
      color: #666;
    }
  }
}

.letter-spacing {
  letter-spacing: 0.2rem;
  color: rgba(255, 255, 255, 0.5) !important;
}
