.navigation-bar {
  &.nav-bar {
    @extend .start-0,
      .top-0,
      .end-0,
      .d-flex,
      .align-items-center,
      .justify-content-between,
      .w-100;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 112px;
    color: $white;
    background-color: transparent;
    transition: $transition-base;
    box-shadow: none;

    &.nav-opened {
      background: $primary;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 15px 10px -10px;
    }

    @include max-break-point(tablet-landscape) {
      position: sticky;
      height: 80px;
      padding-right: 0px;
      border-top: 1px solid rgba(22, 26, 37, 0.1);
      background-color: $primary;
    }
  }
  .brand {
    @extend .position-relative,
      .d-flex,
      .justify-content-start,
      .align-items-center;
    z-index: 101;
    height: 100px;
    padding: 20px 20px 20px 40px;
    transition: letter-spacing 600ms ease;
    font-family: "Roboto Condensed", sans-serif;
    font-size: $h2-font-size;
    font-weight: 400;
    color: $white;

    @include max-break-point(tablet) {
      font-size: $h4-font-size;
      padding: 1em;
    }

    &:hover {
      letter-spacing: 4px;
    }

    img {
      height: 100%;
      width: auto;
      object-fit: contain;
      object-position: center;
    }
  }
  .nav-menu {
    @extend .position-fixed, .top-0, .bottom-0, .start-0, .end-0;
    height: 100vh;
    max-height: 100vh;
    padding-top: 112px;
    padding-right: 40px;
    padding-left: 40px;
    background-color: $primary;
  }
  .nav-link {
    @extend .position-relative,
      .overflow-hidden,
      .align-items-center,
      .justify-content-center,
      .text-center,
      .text-uppercase;

    margin-right: auto;
    margin-left: auto;
    padding: 16px 0px;
    transition: color 600ms ease;
    font-family: "Roboto Condensed", sans-serif;
    color: $white;
    font-size: 4.5vh;
    line-height: 5.5vh;
    letter-spacing: 0.7vh;
    text-decoration: none;
  }
  .contact-button {
    @include max-break-point(mobile-phone) {
      font-size: 10px;
    }
  }
}

.nav-link.w--current {
  color: $white;
}

.nav-link.w--open {
  left: auto;
  right: auto;

  justify-content: center;

  align-items: center;
}
.logo-description {
  margin-left: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 16px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.3);
  font-size: 9px;
  line-height: 16px;
  letter-spacing: 2.2px;
  text-transform: uppercase;

  @include max-break-point(tablet) {
    display: none;
  }
}
.menu-icon {
  width: 24px;
  padding-top: 8px;
}
.menu-button {
  z-index: 10;
  width: 56px;
  height: 56px;
  padding: 15px 16px;
  border-radius: 50%;
  font-family: "Roboto Condensed", sans-serif;
  color: $white;
  font-weight: 700;
}
.menu-button.w--open {
  background-color: transparent;

  .menu-icon-line-top {
    transform: translate3d(0px, 4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
  .menu-icon-line-bottom {
    transform: translate3d(0px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
  }
}
.menu-icon-line-top {
  height: 2px;
  margin-bottom: 6px;
  background-color: $white;
  transition: 0.3s ease-in-out;
}
.menu-icon-line-bottom {
  height: 2px;
  background-color: $white;
  transition: 0.3s ease-in-out;
}
.navigation {
  position: relative;
  z-index: 10;

  display: flex;
  height: 100%;
  padding-right: 24px;
  padding-left: 20px;

  justify-content: flex-end;

  align-items: center;

  flex: 1;
}
.nav-bar-info {
  position: relative;
  z-index: 101;

  display: flex;
  padding-right: 16px;
  padding-left: 16px;

  justify-content: flex-end;

  align-items: center;
}
.nav-bar-contacts {
  padding-right: 32px;
  padding-left: 32px;
  font-size: 11px;
  line-height: 20px;
  text-align: right;

  @include max-break-point(tablet-landscape) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @include max-break-point(tablet) {
    padding-right: 16px;
  }

  @include max-break-point(mobile-phone) {
    display: none;
  }
}
.nav-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.nav-dropdown-list {
  height: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;

  &.w--open {
    opacity: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }
}

.nav-menu-links {
  position: relative;

  display: flex;
  min-height: 100%;
  padding-bottom: 120px;

  flex-direction: column;

  justify-content: center;

  align-items: center;
}
.nav-dropdown-link {
  margin-right: 0px;
  margin-left: 0px;
  padding: 12px 16px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 13px;
  &:hover {
    color: $white;
  }
}
.nav-dropdown-link.w--current {
  color: $white;
}
.nav-bottom-right-info {
  position: absolute;
  left: auto;
  top: auto;
  right: 0px;
  bottom: 44px;
  color: #888;
  font-size: 11px;
  line-height: 20px;
  text-align: right;
}
.social-buttons {
  display: flex;
  margin-right: -3px;
  margin-left: -3px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.social-buttons.nav-menu-social {
  position: absolute;
  left: 0px;
  top: auto;
  right: auto;
  bottom: 40px;
}
.social-button {
  display: flex;
  width: 40px;
  height: 40px;
  margin: 3px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #383838;
  transition: background-color 600ms ease,
    border-color 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover {
    border-color: transparent;
    background-color: $primary-color;
  }
}
.link-highlight {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  display: block;
  width: 12px;
  height: 2px;
  margin-right: auto;
  margin-left: 0px;
  background-color: $primary-color;
  .nav-link-line {
    bottom: 45%;
    height: 3px;
  }
}
@include max-break-point(tablet-landscape) {
  .social-buttons.nav-menu-social {
    bottom: 32px;
  }
  .navigation-bar .nav-link {
    font-size: 40px;
    line-height: 56px;
  }
  .nav-dropdown-list.w--open {
    flex-wrap: wrap;
  }
}
@include max-break-point(tablet) {
  .social-buttons.nav-menu-social {
    bottom: 16px;
  }
  .link-highlight.nav-link-line {
    bottom: 40%;
    height: 3px;
  }
  .navigation-bar .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 32px;
    line-height: 48px;
    white-space: break-spaces;
  }
}
@include max-break-point(mobile-phone) {
  .nav-menu-links {
    padding-bottom: 160px;
  }
  .social-buttons.nav-menu-social {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 56px;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
  }
}
