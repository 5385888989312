.filters {
  @extend .text-center;
  margin-top: 5em;

  ul {
    padding: 0;
    margin: 0 0 30px 0;
    li {
      @extend .d-inline-block;
      cursor: pointer;
      padding: 20px;
      transition: 0.3s all ease;
      &:hover {
        color: $primary-color;
      }
    }
    li.active {
      color: $primary-color;
    }
  }
}
.portfolio-overlay {
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #2d2e32;
  z-index: 1000;
  overflow-x: auto;

  .menu-button.w--open {
    background-color: transparent;

    .menu-icon-line-top {
      transform: translate3d(0px, 4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(45deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    }
    .menu-icon-line-bottom {
      transform: translate3d(0px, -4px, 0px) scale3d(1, 1, 1) rotateX(0deg)
        rotateY(0deg) rotateZ(-45deg) skew(0deg, 0deg);
      transform-style: preserve-3d;
    }
  }
}
.modal-content {
  width: 100%;
}
.single-portfolio {
  display: block;
  margin-bottom: 50px;
  border-radius: 5px;

  .relative {
    margin-bottom: 20px;
  }
  .p-inner {
    text-align: center;
    h4 {
      font-size: 20px;
    }
    .cat {
      font-size: 12px;
      text-transform: uppercase;
      color: $text-color;
    }
  }
}
.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.thumb {
  .overlay-bg {
    opacity: 0;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
}
@media screen and (prefers-reduced-motion: reduce) {
  .thumb {
    .overlay-bg {
      transition: none;
    }
  }
}
