$white: #ffffff;
$black: #000000;

$green: #62e9a6;
$grey: #45504d;
$dimgrey: #66676b;
$red: #e44a4a;
$green-600: #008f68;
$primary: #2d2e32;
$dark-bg: #28292d;
$light-grey: #d1d3d1;
$text-color: #f4f4f4;

$primary-color: $green;
$primary-gradient-color: linear-gradient(
  180deg,
  $green-600 59.1%,
  $green 70.31%
);

// shadow
$shadow: rgba(50, 50, 93, 0.25) 0 0.125em 0.3125em -0.0625em,
  rgba(0, 0, 0, 0.3) 0 0.0625em 0.1875em -0.0625em;
$dashboard-item-shadow: rgba(0, 0, 0, 0.19) 0 0.625em 1.25em,
  rgba(0, 0, 0, 0.23) 0 0.375em 0.375em;

// Body
// Settings for the `<body>` element.

$body-bg: $black;
$body-color: $white;

$font-family-base: "Didact Gothic";
$font-family-title: "BodoniFLF";
$font-family-head: "Nexa Bold";
$font-family-body: "Inter";
$font-family-code: "Source Code Pro";

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 700;

// border
// $border-color: #{var(--border-color)};

// scss-docs-start border-radius-variables
$border-radius: 0.625rem;
$border-radius-sm: 0.2rem;
$border-radius-lg: 50%;

// scss-docs-end border-radius-variables

$line-height-base: 1.5;

// Heading

$headings-color: $white;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.375;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: 1em;
$h6-font-size: $font-size-base;

$headings-font-weight: 700;
$headings-line-height: 1.4;

// Links
// Style anchor elements.
$link-color: $primary-color;
// $link-hover-color: $gray-900;

$border-color: #344048;
// scss-docs-end border-variables

$input-btn-padding-y: 1.125rem;
$input-btn-padding-x: 1.125rem;
$input-btn-font-family: "Nexa Bold";
$input-btn-font-size: 0.75rem;
$input-btn-line-height: 0.875;
$btn-line-height: $input-btn-line-height;

// Buttons
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;

$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;

//card
$card-background-gradient-color: linear-gradient(
  180deg,
  #008f68 68.18%,
  #00c08b 75.87%
);
