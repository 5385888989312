#section-home {
  background: $primary;
  min-height: calc(50em - 8.5em);
  overflow: hidden;

  @include max-break-point(tablet) {
    padding-top: 2em;
  }
}

.landing-page {
  .content-section {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @include max-break-point(tablet-landscape) {
      height: 80vh;
    }
  }
}

.strong-quote {
  h1 {
    font-size: 3em;
    font-weight: 700;
    color: $text-color;

    @include max-break-point(large-desktop) {
      font-size: 2em;
    }

    @include max-break-point(tablet) {
      font-size: 1.5em;
    }
  }
}

.portfolio-image-section {
  flex-basis: 50%;

  @include max-break-point(tablet) {
    flex-basis: 100%;
  }

  .portfolio-image {
    border-radius: 50%;

    &::before {
      position: absolute;
      content: "";
      left: 0%;
      top: 0%;
      background: $dark-bg;
      border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
      will-change: border-radius, transform, opacity;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
      display: block;

      @include size(55em);
      @include animation(sliderShape, 20s, linear, infinite);

      @include max-break-point(mobile-phone) {
        @include size(20em);
      }
    }
    &::after {
      position: absolute;
      content: "";
      left: 10%;
      top: 20%;
      background: $primary;
      border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
      will-change: border-radius, transform, opacity;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
      display: block;
      @include size(40em);
      @include animation(sliderShape, 12s, linear, infinite);

      @include max-break-point(mobile-phone) {
        @include size(15em);
      }
    }
    img {
      position: relative;
      z-index: 1;
      @include size(auto, 40em);

      @include max-break-point(tablet) {
        @include size(auto, 20em);
      }
    }
  }
}
.chat-line {
  font-size: $h3-font-size;
  font-weight: $font-weight-bold;
  font-family: $font-family-base;
}

.tag-line p {
  background-color: $primary-color;
  width: fit-content;
  font-weight: $font-weight-bold;
}

.ea-item {
  @extend .position-absolute, .rounded-circle;
  @include size(10em);
  padding: 2em;
  z-index: 2;

  &::before {
    position: absolute;
    content: "";
    left: 0%;
    top: 0%;
    @include size(10em);
    background: $dark-bg;
    border-radius: 50%;
    will-change: border-radius, transform, opacity;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    @include max-break-point(mobile-phone) {
      @include size(4em);
    }
  }

  img {
    position: relative;
    object-fit: cover;
  }
}

.ea-item {
  &.html {
    top: 4em;
    left: 0em;
  }
  &.css {
    top: 20em;
    left: 100%;

    @include max-break-point(mobile-phone) {
      left: auto;
      top: 10em;
      right: 5%;
    }
  }

  &.js {
    @include size(15em);
    bottom: 4em;
    left: 60%;
    &::before {
      @include size(15em);

      @include max-break-point(mobile-phone) {
        @include size(4em);
      }
    }

    @include max-break-point(mobile-phone) {
      @include size(4em);
    }
  }
  &.react {
    bottom: 10em;
    left: 0em;
  }
  &.django {
    @include size(12em);
    top: 6%;
    right: 0em;
    &::before {
      @include size(12em);

      @include max-break-point(mobile-phone) {
        @include size(4em);
      }
    }
    @include max-break-point(mobile-phone) {
      @include size(4em);
    }
  }

  @include max-break-point(mobile-phone) {
    @include size(4em);
    padding: 0.5em;
  }
}

.experience-section {
  @extend .mt-4;

  .years,
  .projects {
    color: #888888;
    display: flex;
    align-items: center;

    span {
      font-size: 1em;
    }

    .count {
      color: white;
      padding-right: 0.3em;
      font-size: 3em;
    }
  }
  .projects {
    margin-left: 3em;

    @include max-break-point(tablet) {
      margin-left: 0;
    }
  }
}
