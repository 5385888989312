.fs-7 {
  font-size: 0.875rem !important;
  line-height: 0.875rem !important;
}

h2 {
  font-family: $font-family-title;
  font-size: $h2-font-size;
  line-height: 1.875rem;
}

h3 {
  font-family: $font-family-head;
  line-height: $h3-font-size;
  margin-bottom: 0.3125rem;
}

h4,
h5 {
  font-family: $font-family-head;
}

strong {
  font-family: $font-family-head;
}

.number {
  background-color: $primary-color;
  border-radius: $border-radius-lg;
  display: inline-block;
  font-family: $font-family-title;
  font-size: 1.25rem;
  height: 1.6875rem;
  min-width: 1.6875rem;
  padding: 0.0675rem;
  text-align: center;
}

.tag {
  background-color: $primary-color;
  border-radius: 0.3125rem;
  font-family: "Nexa Bold";
  font-size: 1.25rem;
  line-height: 1.25rem;
  padding: 0.75rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lg {
  font-size: 2.1875rem;
}

.mt-20x {
  margin-top: 4.5rem;
}

.nexa-bold {
  font-family: "Nexa Bold";
}

.lock {
  background-color: rgba($color: $black, $alpha: 0.6);

  .icon-lock {
    @include size(4.68em);
    padding: 1.2rem 0;
    top: 1.56rem;
    transform: translatex(-50%);
  }
}

.lh18 {
  line-height: 1.125rem;
}

.text-primary {
  color: $primary-color !important;
}

.cursor-alias {
  cursor: alias;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-auto {
  cursor: auto;
}

.cursor-cell {
  cursor: cell;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-copy {
  cursor: copy;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-default {
  cursor: default;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-none {
  cursor: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-text {
  cursor: text;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-wait {
  cursor: wait;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

.site-section {
  padding-top: 10em;
  font-family: $font-family-code;
}

.no-padding {
  padding: 0;
}

.section-heading {
  margin-bottom: 30px;
  position: relative;
  z-index: 10;
  h2 {
    position: relative;
    font-weight: 200;
    padding-top: 20px;
    font-size: 30px;
    strong {
      font-weight: 800;
    }
  }
}
.section-heading.text-center {
  h2 {
    &:before {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

.btn-primary {
  color: #212529;
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover {
    color: #212529;
    background-color: $primary-color;
    border-color: $primary-color;
  }
}
