@include keyframe(dotPulseBefore) {
  0% {
    box-shadow: 624em 0 0 -5px $primary-color;
  }

  30% {
    box-shadow: 624em 0 0 2px $primary-color;
  }

  60%,
  100% {
    box-shadow: 624em 0 0 -5px $primary-color;
  }
}

@include keyframe(dotPulse) {
  0% {
    box-shadow: 624.375em 0 0 -5px $primary-color;
  }

  30% {
    box-shadow: 624.375em 0 0 2px $primary-color;
  }

  60%,
  100% {
    box-shadow: 624.375em 0 0 -5px $primary-color;
  }
}

@include keyframe(dotPulseAfter) {
  0% {
    box-shadow: 625.875em 0 0 -5px $primary-color;
  }

  30% {
    box-shadow: 625.875em 0 0 2px $primary-color;
  }

  60%,
  100% {
    box-shadow: 625.875em 0 0 -5px $primary-color;
  }
}

@include keyframe(sliderShape) {
  0%,
  100% {
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  34% {
    border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
    transform: translate3d(0, 5px, 0) rotateZ(0.01deg);
  }
  50% {
    transform: translate3d(0, 0, 0) rotateZ(0.01deg);
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
    transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
  }
}