.site-service-item {
  background: $primary;
  padding: 3em;
  box-shadow: 0px 0px 15px -5px rgba($color: #000000, $alpha: 0.6);
  border-radius: 8px;

  .service-header {
    color: $primary-color;

    h3 {
      font-size: 20px;
      font-weight: 200;
      color: $primary-color;
    }
  }
}
.site-link {
  position: relative;
  svg {
    position: relative;
    top: 2px;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  &:hover {
    svg {
      position: relative;
      margin-left: 20px;
    }
  }
}
.service-link {
  margin: 1em 1em 1em 0;
  font-size: 1em;
  text-transform: uppercase;
  padding-bottom: 1em;
  flex: 25% 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

  svg {
    font-size: 3em;
    padding: 0.3em;
    display: block;
  }
}
