.site-footer {
  padding: 3em 0;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  .social-item {
    width: 60px;
    height: 60px;
    background: #333;
  }
}
