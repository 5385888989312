.home-container {
  background-size: cover;
  width: 100%;
  height: 100%;
  background: $dark-bg;
}

.introduction-wrapper {
  padding-left: 5em;
  
  @include max-break-point(tablet) {
    padding-left: initial;
  }
}

.section-heading {
  span {
    color: $dimgrey;
  }
  h2 {
    font-family: $font-family-code;
    font-size: 3em;
    line-height: 1;
    font-weight: 400;
  }
}

.section-caption {
  font-size: 1.5em;
  font-weight: 400;
  padding: 2em 0;
}

.section-description {
  font-weight: 500;
  color: $dimgrey;
  font-size: 1.2em;

  p {
    line-height: 1.8;
  }

  @include max-break-point(mobile-phone) {
    font-size: 1em;
  }
}
